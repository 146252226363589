.modal-header {
  border-bottom: 1px solid #f1f1f1;

  .h4 {
    font-weight: normal;
    font-size: 1.1rem;
  }
}

.modal-content {
  border: none !important;
}